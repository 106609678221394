<template>
  <a-card :bordered="false">
    <page-header-wrapper :Style="{ padding: '3px 10px'}" :title="false"></page-header-wrapper>
    <a-form layout="inline">
      <a-form-item label="搜索类型">
        <a-select v-model="searchFeildParam.searchType" allow-clear style="min-width:100px" placeholder="请选择">
          <a-select-option value="orderSn">订单号</a-select-option>
          <a-select-option value="pingaOrderSn">B端渠道单号</a-select-option>
          <a-select-option value="truename">姓名</a-select-option>
          <a-select-option value="mobile">手机号</a-select-option>
          <a-select-option value="nosoName">医院</a-select-option>
          <a-select-option value="channelProduct">渠道产品</a-select-option>
          <a-select-option value="productName">一级产品</a-select-option>
          <a-select-option value="productChildName">二级产品</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="" pron="searchText">
        <a-input v-model="queryParam.searchText" allow-clear placeholder="请输入搜索内容"/>
      </a-form-item>

      <a-form-item label="时间类型">
        <a-select v-model="searchFeildParam.searchName" allow-clear style="min-width:100px" placeholder="请选择">
          <a-select-option value="subscribeDate">预约时间</a-select-option>
          <a-select-option value="createTime">下单时间</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="开始时间">
        <a-date-picker
          show-time
          allow-clear
          v-model="queryParam.startTime"
          format="YYYY-MM-DD"
          placeholder="开始时间"
        />
      </a-form-item>
      <a-form-item label="结束时间">
        <a-date-picker
          show-time
          allow-clear
          v-model="queryParam.endTime"
          format="YYYY-MM-DD"
          placeholder="结束时间"
        />
      </a-form-item>
      <a-form-item label="类型">
        <a-select
          v-model="queryParam.orderType"
          allow-clear
          style="min-width:100px"
          :default-value="0"
          placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="1">体检</a-select-option>
          <a-select-option :value="2">门诊</a-select-option>
          <a-select-option :value="3">住院</a-select-option>
          <a-select-option :value="4">咨询</a-select-option>
          <a-select-option :value="5">其他</a-select-option>
          <a-select-option :value="6">检查</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="渠道">
        <a-select
          v-model="queryParam.channelId"
          allow-clear
          style="min-width:100px"
          :default-value="0"
          placeholder="请选择">
          <a-select-option v-for="item in channelData" :key="item.channelId" :value="item.channelId">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态">
        <a-select v-model="queryParam.status" allow-clear style="min-width:100px" :default-value="0" placeholder="请选择">
          <a-select-option :value="0">全部</a-select-option>
          <a-select-option :value="8">待支付</a-select-option>
          <a-select-option :value="1">待跟进</a-select-option>
          <a-select-option :value="2">待预约</a-select-option>
          <a-select-option :value="3">已预约</a-select-option>
          <a-select-option :value="4">服务完成</a-select-option>
          <a-select-option :value="5">服务取消</a-select-option>
          <a-select-option :value="6">超时爽约</a-select-option>
          <a-select-option :value="7">已取消</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
      </a-form-item>
      <a-form-item>
        <a-button style="margin-left: 8px" @click="download">下载</a-button>
      </a-form-item>
    </a-form>

    <div class="table-operator" align="right">
      <a-button v-action:add icon="plus" type="primary" @click="handleAdd()">新建</a-button>
    </div>
    <s-table
      ref="table"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 1500,y: 600 }"
      bordered
      rowKey="key"
      size="default"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="handleDetail(record)">详情</a>
        <a-divider type="vertical" v-if="record.status==1"/>
        <a v-if="record.status==1" @click="handleEdit(record)">确认需求</a>
        <a-divider v-if="record.status==1||record.status==2" v-action:state type="vertical"/>
        <a v-if="record.status==1 ||record.status==2" @click="handleCancelOrder(record)">取消服务</a>
        <a-divider v-if="record.status==2" type="vertical"/>
        <a v-if="record.status==2" @click="handleupdateEdit(record)">更新需求</a>
        <a-divider v-if="record.status==2" type="vertical"/>
        <a v-if="record.status==2" @click="handleDemandOrder(record)">预约完成</a>
        <a-divider v-action:state type="vertical"/>
        <a v-if="record.status==3" @click="handlechangeDemandOrder(record)">更新预约</a>
        <a-divider v-action:state type="vertical"/>
        <a v-if="record.status==3" @click="handleFinish(record)">结束服务</a>
      </span>
    </s-table>
    <a-modal
      v-model="showvisible"
      title="订单详情"
      :width="900"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancelDetail">
          取消
        </a-button>
        <a-button key="addremark" type="primary" :loading="loading" @click="openAddRemarkModal">
          添加备注
        </a-button>
        <a-button key="submit" type="primary" :loading="loading">
          确认
        </a-button>
      </template>
      <detail ref="detail"/>
      <orderlog ref="detailorderlog"></orderlog>
    </a-modal>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      title="确认需求"
      :width="900"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <formCreate
        ref="createModal"
        :model="mdl"
      />
      <orderlog ref="orderlog"></orderlog>
    </a-modal>
    <a-modal
      v-model="addvisible"
      title="新增订单"
      :width="900"
      :confirmLoading="confirmaddLoading"
      @cancel="handleAddCancel"
      @ok="handleAddOk"

    >
      <addCreate
        ref="addModal"
        :model="addmdl"
      />
    </a-modal>
    <a-modal
      v-model="updatevisible"
      :confirmLoading="updateloading"
      title="更新需求"
      :width="900"
      @cancel="handleupdateCancel"
      @ok="handleupdateOk"
    >
      <formCreate
        ref="updateModal"
        :model="mdl"
      />
      <orderlog ref="orderlog"></orderlog>
    </a-modal>
    <a-modal
      v-model="demandvisible"
      :confirmLoading="loading"
      title="预约完成"
      :width="900"
      ok-text="确认预约"
      @cancel="handleDemandCancel"
      @ok="handleDemandOk"
    >
      <yuyueDemandFrom ref="demand" @submityuyueForm="submityuyueForm"/>
      <orderlog ref="demandorderlog"></orderlog>
    </a-modal>

    <a-modal
      v-model="changedemandvisible"
      :confirmLoading="loading"
      title="更新预约"
      :width="900"
      ok-text="确认更新"
      @cancel="handleChangeDemandCancel"
      @ok="handleChangeDemandOk"
    >
      <changeyuyuefrom ref="changedemand" @submitchangeyuyueForm="submitchangeyuyueForm"/>
      <orderlog ref="changeorderlog"></orderlog>
    </a-modal>

    <a-modal
      v-model="finishvisible"
      :confirmLoading="loading"
      title="结束服务"
      :width="900"
      ok-text="确认"
      @cancel="handleFinishCancel"
      @ok="handleFinishdOk"
    >
      <finishForm ref="finishFrom" @finishishuxin="finishishuxin"/>
      <orderlog ref="finishorderlog"></orderlog>
    </a-modal>
    <a-modal
      v-model="shownewvisible"
      @ok="handleUpdateRemark"
      title="添加备注"
    >
      <a-form
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-form-item
          label="备注"
          :required="true"
        >
          <a-textarea
            v-model="nowRecord.newRemarks"
            name="newRemarks"
            placeholder="请输入备注"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>

<script>
import tree from 'vue-giant-tree'
import { STable } from '@/components'
import {
  FetchList,
  update,
  statuscancel,
  updateyuyue,
  updateFinishYuyue,
  create,
  OrderDownload,
  comfirmUpdate,
  updateNewRemarks
} from '@/api/order'
import { formatterTime, formatterDateTime } from '@/utils/util'
import moment from 'moment'
import detail from './detail'
import orderlog from './orderlog'
import pick from 'lodash.pick'
import formCreate from './from'
import yuyueDemandFrom from './yuyuefrom'
import changeyuyuefrom from './changeyuyuefrom'
import finishForm from './finishForm'
import addCreate from './addfrom'
import { allList as channelList } from '@/api/channel'
import { allList as channelproductlList } from '@/api/channelproduct'

export default {
  components: {
    STable,
    tree,
    detail,
    formCreate,
    yuyueDemandFrom,
    changeyuyuefrom,
    orderlog,
    finishForm,
    addCreate
  },
  data () {
    return {
      check: {
        enable: true
      },
      channelData: [],
      productdata: [],
      finishvisible: false,
      changedemandvisible: false,
      demandloading: false,
      demandvisible: false,
      selectedRowKeys: [],
      selectedRows: [],
      showvisible: false,
      visible: false,
      confirmLoading: false,
      loading: false,
      updatevisible: false,
      updateloading: false,
      title: '',
      mdl: null,
      addtitle: '',
      addmdl: null,
      confirmaddLoading: false,
      addvisible: false,
      shownewvisible: false,
      nowRecord: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      // 查询参数
      queryParam: {},
      searchFeildParam: {
        searchName: undefined,
        searchType: undefined
      },
      orgTree: [],
      // 表头
      columns: [
        {
          title: '订单编号',
          dataIndex: 'orderSn'
        },
        {
          title: 'B端渠道单号',
          dataIndex: 'pingaOrderSn'
        },
        {
          title: '渠道',
          dataIndex: 'channelName'
        },
        {
          title: '渠道产品',
          dataIndex: 'channelProduct'
        },
        {
          title: '一级产品',
          dataIndex: 'productName'
        },
        {
          title: '二级产品',
          dataIndex: 'productChildName'
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          sorter: true,
          customRender: (val) => {
            let str = ''
            switch (val) {
              case 1:
                str = '体检'
                break
              case 2:
                str = '门诊'
                break
              case 3:
                str = '住院'
                break
              case 4:
                str = '咨询'
                break
              case 5:
                str = '其他'
                break
              case 6:
                str = '检查'
                break
            }
            return str
          }
        },
        {
          title: '姓名',
          dataIndex: 'truename'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '地区',
          dataIndex: 'regionName'
        },

        {
          title: '医院名称',
          dataIndex: 'nosoName'
        },

        {
          title: '意向时间',
          dataIndex: 'subscribeDate',
          customRender: (val) => {
            return formatterDateTime(val)
          }
        },

        {
          title: '状态',
          dataIndex: 'status',
          sorter: true,
          customRender: (val) => {
            let str = ''
            switch (val) {
              case 1:
                str = '待跟进'
                break
              case 2:
                str = '待预约'
                break
              case 3:
                str = '已预约'
                break
              case 4:
                str = '服务完成(完成)'
                break
              case 5:
                str = '服务取消(完成)'
                break
              case 6:
                str = '服务爽约(完成)'
                break
              case 7:
                str = '已取消'
                break
              case 8:
                str = '待支付'
                break
            }
            return str
          }
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
          sorter: true,
          customRender: (val) => {
            return formatterTime(val)
          }
        }, {
          title: '操作',
          width: '230px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        var searchParam = []
        searchParam = this.getCommonOperate(searchParam, ['=__status', '=__orderType','=__mobile', '=__channelId', 'like__orderSn', 'like__pingaOrderSn', 'like__nosoName', 'like__truename'])
        if (this.searchFeildParam.searchName !== undefined) {
          if (this.queryParam.startTime != null) {
            var startTime = moment(moment(this.queryParam.startTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
            searchParam['>=__' + this.searchFeildParam.searchName] = startTime
          }
          if (this.queryParam.endTime != null) {
            var endTime = moment(moment(this.queryParam.endTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
            searchParam['<=__' + this.searchFeildParam.searchName] = endTime
          }
        }
        if (this.searchFeildParam.searchType !== undefined) {
          searchParam['like__' + this.searchFeildParam.searchType] = this.queryParam.searchText
        }
        return FetchList(Object.assign(parameter, searchParam))
          .then(res => {
            return res.result
          })
      }
    }
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    getCommonOperate (searchParam, fileds) {
      for (var key in this.queryParam) {
        var value = this.queryParam[key]
        if (typeof value !== 'number') {
          if (value === undefined || value === '' || value === null) {
            continue
          }
          value = value.toString().trim()
          if (value.length === 0) continue
        } else {
          if (value === 0) {
            continue
          }
        }
        for (var j = 0; j < fileds.length; j++) {
          var array = fileds[j].split('__')
          if (array[1] === key) {
            searchParam[fileds[j]] = value
          }
        }
      }
      return searchParam
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onClearSelected () {
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    handleDetail (record) {
      this.nowRecord = record
      this.showvisible = true
      this.$nextTick(function () {
        this.$refs.detail.setOrderId(record.orderId)
        this.$refs.detailorderlog.setOrderId(record.orderId, this.nowRecord.newRemarks)
      })
    },
    handleCancelDetail () {
      this.showvisible = false
    },
    openAddRemarkModal () {
      this.shownewvisible = true
    },
    handleUpdateRemark () {
      updateNewRemarks({ orderId: this.nowRecord.orderId, newRemarks: this.nowRecord.newRemarks }).then(res => {
        if (res.code === 1) {
          this.shownewvisible = false
          this.$refs.detailorderlog.refreshNewRemarks(this.nowRecord.newRemarks)
        }
        self.$message.error(res.message)
      })
    },
    handleupdateEdit (record) {
      this.title = '编辑'
      this.updatevisible = true
      this.$nextTick(function () {
        this.$refs.orderlog.setOrderId(record.orderId)
        const form = this.$refs.updateModal.form
        const fields = ['orderId', 'mealId', 'orderType', 'orderSn', 'status', 'truename', 'mobile', 'idcard', 'remarks', 'regionId', 'nosoId', 'nosoDepId', 'channelId', 'channelProductId', 'addressId', 'doctorId', 'nosoDepId', 'subscribeDate']
        const datafleld = record
        console.log(datafleld)
        datafleld.subscribeDate = moment(datafleld.subscribeDate)
        this.$refs.updateModal.ChangeHideValue(datafleld.orderType)
        form.setFieldsValue(pick(datafleld, fields))
        this.$refs.updateModal.getNosodochiumList(datafleld.regionId)
        this.$refs.updateModal.getNosodochiumdeplList(datafleld.nosoId)
        this.$refs.updateModal.getAddresslList(datafleld.nosoId)
        this.$refs.updateModal.getProductlList(datafleld.channelId)
        this.$refs.updateModal.getDoctorList(datafleld.nosoDepId)
        if (record.isEdit === 1) {
          this.$refs.createModal.setIsEdit(true)
        } else {
          this.$refs.createModal.setIsEdit(false)
        }
      })
    },
    handleupdateCancel () {
      this.updatevisible = false
    },
    handleupdateOk () {
      const self = this
      const form = this.$refs.updateModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          const params = {}
          Object.assign(params, values)
          params.subscribeDate = moment(values.subscribeDate).valueOf()
          update(params).then(res => {
            if (res.code === 1) {
              this.updatevisible = false
              this.updateLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('更新成功')
            } else {
              self.$message.error(res.message)
            }
          })
        } else {
          this.updateLoading = false
        }
      })
    },
    handleEdit (record) {
      this.title = '编辑'
      this.visible = true
      this.$nextTick(function () {
        this.$refs.orderlog.setOrderId(record.orderId)
        const form = this.$refs.createModal.form
        const fields = ['orderId', 'mealId', 'orderSn', 'status', 'truename', 'mobile', 'idcard', 'remarks', 'regionId', 'nosoId', 'nosoDepId', 'channelId', 'channelProductId', 'addressId', 'doctorId', 'nosoDepId', 'subscribeDate', 'orderType']
        const datafleld = record
        datafleld.subscribeDate = moment(datafleld.subscribeDate)
        form.setFieldsValue(pick(datafleld, fields))
        this.$refs.createModal.getNosodochiumList(record.regionId)
        this.$refs.createModal.getNosodochiumdeplList(record.nosoId)
        this.$refs.createModal.getAddresslList(record.nosoId)
        this.$refs.createModal.getProductlList(record.channelId)
        this.$refs.createModal.ChangeOrderValue(record.orderType)
        if (record.isEdit === 1) {
          this.$refs.createModal.setIsEdit(true)
        } else {
          this.$refs.createModal.setIsEdit(false)
        }
      })
    },
    handleCancel () {
      this.visible = false
    },
    handleOk () {
      const self = this
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          const params = {}
          Object.assign(params, values)
          params.subscribeDate = moment(values.subscribeDate).valueOf()
          comfirmUpdate(params).then(res => {
            if (res.code === 1) {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('修改成功')
            } else {
              self.$message.error(res.message)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancelOrder (record) {
      const self = this
      this.$confirm({
        title: '确定要取消吗？',
        content: '取消后将不可恢复，请谨慎操作！',
        okText: '确认',
        cancelText: '取消',
        onOk () {
          statuscancel(record)
            .then(res => {
              if (res.code === 1) {
                // self.$refs.table.refresh(true)
                self.$refs.table.refresh(true)
                self.$message.info(res.message)
              } else {
                self.$refs.table.refresh(true)
                self.$message.error(res.message)
              }
            })
        }

      })
    },
    handleDemandOrder (record) {
      this.demandvisible = true
      this.$nextTick(function () {
        this.$refs.demand.setOrderId(record.orderId)
        this.$refs.demandorderlog.setOrderId(record.orderId)
      })
    },
    handleDemandCancel () {
      this.demandvisible = false
    },
    handleDemandOk () {
      this.$nextTick(function () {
        this.$refs.demand.onSubmit()
      })
    },
    submityuyueForm (value) {
      const self = this
      value.subscribeDate = moment(value.subscribeDate).valueOf()
      updateFinishYuyue(value)
        .then(res => {
          if (res.code === 1) {
            self.demandvisible = false
            self.$refs.table.refresh()
            self.$message.info(res.message)
          } else {
            self.$message.error(res.message)
          }
        })
    },
    handlechangeDemandOrder (record) {
      this.changedemandvisible = true
      this.$nextTick(function () {
        this.$refs.changedemand.setOrderId(record.orderId)
        this.$refs.changeorderlog.setOrderId(record.orderId)
      })
    },

    handleChangeDemandCancel () {
      this.changedemandvisible = false
    },
    handleChangeDemandOk () {
      this.$nextTick(function () {
        this.$refs.changedemand.onSubmit()
      })
    },
    submitchangeyuyueForm (value) {
      const self = this
      value.subscribeDate = moment(value.subscribeDate).valueOf()
      updateyuyue(value)
        .then(res => {
          if (res.code === 1) {
            self.changedemandvisible = false
            self.$refs.table.refresh()
            self.$message.info(res.message)
          } else {
            self.$message.error(res.message)
          }
        })
    },
    handleFinish (record) {
      this.finishvisible = true
      this.$nextTick(function () {
        this.$refs.finishFrom.setOrderId(record.orderId)
        this.$refs.finishorderlog.setOrderId(record.orderId)
      })
    },
    handleFinishCancel () {
      this.finishvisible = false
    },
    handleFinishdOk () {
      this.finishvisible = true
      this.$nextTick(function () {
        this.$refs.finishFrom.onSubmit()
      })
    },
    finishishuxin () {
      this.$refs.table.refresh()
      this.finishvisible = false
    },
    handleAdd () {
      this.addtitle = '新增'
      this.addmdl = null
      this.addvisible = true
      this.$nextTick(function () {
        this.$refs.addModal.form.resetFields()
        this.$refs.addModal.ChangeOrderValue(1)
      })
    },
    handleAddCancel () {
      this.addvisible = false
    },
    handleAddOk () {
      const self = this
      const form = this.$refs.addModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          const params = {}
          Object.assign(params, values)
          params.subscribeDate = moment(values.subscribeDate).valueOf()
          create(params).then(res => {
            if (res.code === 1) {
              this.addvisible = false
              this.confirmaddLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              self.$refs.table.refresh()
              self.$message.info('新增成功')
            } else {
              self.$message.error(res.message)
            }
          })
        } else {
          this.confirmaddLoading = false
        }
      })
    },
    download () {
      var searchParam = []
      searchParam = this.getCommonOperate(searchParam, ['=__status', '=__mobile', 'like__orderSn', 'like__pingaOrderSn', 'like__nosoName', 'like__truename'])
      if (this.searchFeildParam.searchName !== undefined) {
        if (this.queryParam.startTime != null) {
          var startTime = moment(moment(this.queryParam.startTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
          searchParam['>=__' + this.searchFeildParam.searchName] = startTime
        }
        if (this.queryParam.endTime != null) {
          var endTime = moment(moment(this.queryParam.endTime).format('YYYY-MM-DD') + ' 00:00:00').valueOf()
          searchParam['<=__' + this.searchFeildParam.searchName] = endTime
        }
      }
      if (this.searchFeildParam.searchType !== undefined) {
        searchParam['like__' + this.searchFeildParam.searchType] = this.queryParam.searchText
      }
      const parameter = {}
      OrderDownload(Object.assign(parameter, searchParam)).then(res => {
        const fileName = '订单下载.xls'
        const content = res
        const blob = new Blob([content], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    getChannelList () {
      channelList().then(res => {
        this.channelData = res.result
      })
    },
    getProductlList (channelId) {
      this.queryParam.channelProductId = undefined
      this.productdata = []
      channelproductlList({ channelId: channelId }).then(res => {
        this.productdata = res.result
        this.$forceUpdate()
      })
    },
    channelProductSelect () {
      this.$forceUpdate()
    },
    channelProduct (value) {
      this.getProductlList(value)
    }
  },
  mounted () {
    this.getChannelList()
  }
}
</script>
